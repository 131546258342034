// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/stripe/config.ts"
);
import.meta.hot.lastModified = "1730916919165.2097";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/ranks',
  apiPath: `/api/stripe`,
}
